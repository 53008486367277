/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ionic/angular/css/core.css";
@import "~ngx-toastr/toastr";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

*body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
}

.header-content {
  text-align: center;
  .heading-label {
    margin-top: 5px;
  }
  .heading-value {
    font-size: 2.1em;
    margin-top: 5px;
  }
}

.no-padding-card {
  padding: 5px 15px !important;
}

.mat-dialog-container {
  padding: 0px !important;
  overflow: hidden !important;
}

.ngselectfield .ng-select.ng-select-single .ng-select-container {
  height: 50px;
}

.ngselectfield
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input {
  top: 12px;
}
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: red;
}
/* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  border-color: red transparent transparent;
}
/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: red;
}
.ng-select .ng-select-container {
  height: 50px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 12px;
  padding-bottom: 5px;
  padding-left: 3px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.301);
}

.angular-editor[_ngcontent-maa-c383]
  .angular-editor-wrapper[_ngcontent-maa-c383]
  .angular-editor-textarea[_ngcontent-maa-c383] {
  border: 1px solid black;
}

// header dropdown menu css
.mat-menu-panel {
  margin-top: 15px;
  // padding: 5px 15px;
}
.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.notificationBoxContent .mat-badge-content,
.notificationBoxContent .mat-tab-group.mat-primary .mat-ink-bar {
  background: red;
}

.dropdown .mat-list-item-content a {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.dropdown .mat-list-item-content a:hover {
  text-decoration: none;
}
.dropdown mat-list-item.mat-list-item.mat-focus-indicator:hover {
  background: rgb(221 221 221 / 60%);
}

.header-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchBox .mat-list-base {
  padding-top: 0;
}
.selectedClass th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 24px;
  padding-right: 10px;
}
.selectedClass th.mat-header-cell{
  padding-right: 10px;
}
.selectedClass td.mat-cell{
  padding-left: 15px;
}
.selectedClass .title{
  padding-left: 20px;
}
.selectedClass .createdBy{
  padding-left: 15px;
}